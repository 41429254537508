import React from "react";
import article1 from "../../images/Art1.png";
import article2 from "../../images/Art2.png";
import article3 from "../../images/Art3.png";
import article4 from "../../images/Art4.png";
import article5 from "../../images/Art5.png";
import article6 from "../../images/Art6.png";
const PostsBar = () => {
  const delay = (millis) =>
    new Promise((resolve, reject) => {
      setTimeout((_) => resolve(), millis);
    });

  async function pictureExpand (cardId, linkId, infoId) {
    var fullCard = document.getElementById(cardId);
    var learn = document.getElementById(linkId);
    document.getElementById(infoId).style.display = "none";
    learn.style.visibility = "hidden";
    for (let i = 24; i < 42; i++) {
      await delay(8);
      fullCard.style.minWidth = i + "rem";
    }
  };
  async function pictureReset (cardId, linkId, infoId) {
    var fullCard = document.getElementById(cardId);
    var learn = document.getElementById(linkId);
    for (let i = 42; i >= 24; i--) {
      await delay(8);
      fullCard.style.minWidth = i + "rem";
    }
    document.getElementById(infoId).style.display = "block";
    learn.style.visibility = "visible";
    learn.style.marginLeft = "1rem";
  }

  return (
    <section class="text-gray-600 body-font overflow-x-scroll">
      <div class="container px-5 py-10 mx-auto">
        <div class="flex -m-4 overflow-x-auto w-max">
          <div id="article1" className="p-4 w-96">
            <div class="h-full rounded-xl shadow-cla-violate border-[3px] bg-gradient-to-r from-[#C4A962]/30 to-yellow-50 border-[#C4A962] relative">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/33664170/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  id="pic"
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  onMouseOver={()=>{pictureExpand("article1","link1","info1")}}
                  onMouseLeave={()=>{pictureReset("article1","link1","info1")}}
                  src={article1}
                  alt="blog"
                />
              </a>
              <div id="info1" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 ">
                  Coronavirus (Covid-19)
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3 text-[#1C5367]/95">
                  COVID-19 pandemic: from origins to outcomes
                </h1>
                <p class="leading-relaxed mb-3 text-[#1C5367]/95">
                  Severe Acute Respiratory Syndrome Coronavirus-2 (SARS-CoV-2),
                  the causative pathogen for the COVID-19, first emerged in
                  Wuhan, China, in December 2019 and by March 2020, it was
                  declared a pandemic.
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: RohanKumar Ochani, Ameema Asad, Farah Yasmin, and
                  more
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/33664170/"
                    rel="noreferrer"
                    target="_blank"
                    id="link1"
                    class="z-40 bg-gradient-to-r from-[#C4A962] to-[#eecb74] hover:scale-105 drop-shadow-md shadow-cla-violate px-4 py-1 rounded-lg absolute bottom-10"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="article2" class="p-4 md:w-96">
            <div class="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden border-2 border-[#1C5367] relative">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/20871401/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  src={article2}
                  onMouseOver={()=>{pictureExpand("article2","link2","info2")}} 
                  onMouseLeave={()=>{pictureReset("article2","link2","info2")}}
                  alt="blog"
                />
              </a>
              <div id="info2" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                  Children
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3">
                  Childhood obesity
                </h1>
                <p class="leading-relaxed mb-3">
                  As childhood obesity is associated with premature death in
                  adults, a research is critical. This review focuses on the
                  recent proceedings concerning genesis, prevention, and
                  treatment.
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: Thomas Reinehr, Martin Wabitsch
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    id="link2"
                    href="https://pubmed.ncbi.nlm.nih.gov/20871401/"
                    rel="noreferrer"
                    target="_blank"
                    class="bg-gradient-to-r from-[#1C5367] to-[#2e819f] hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-white absolute bottom-10"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="article3" class="p-4 md:w-96">
            <div class="h-full rounded-xl shadow-cla-violate border-[3px] bg-gradient-to-r from-[#C4A962]/30 to-yellow-50 border-[#C4A962] overflow-hidden relative">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/14616041/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  src={article3}
                  onMouseOver={()=>{pictureExpand("article3","link3","info3")}} 
                  onMouseLeave={()=>{pictureReset("article3","link3","info3")}}
                  alt="blog"
                />
              </a>
              <div id="info3" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 text-[#1C5367]/95">
                  Health and Wellness
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3 text-[#1C5367]/95">
                  The PedsQL 4.0 as a pediatric population health measure
                </h1>
                <p class="leading-relaxed mb-3 text-[#1C5367]/95">
                  The application of health-related quality of life (HRQOL) as a
                  pediatric population health measure may facilitate risk
                  assessment and resource allocation, the tracking of community
                  health, the identification of health disparities...
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: James W Varni, Tasha M Burwinkle, Michael Seid,
                  Douglas Skarr
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    id="link3"
                    href="https://pubmed.ncbi.nlm.nih.gov/14616041/"
                    rel="noreferrer"
                    target="_blank"
                    class="bg-gradient-to-r from-[#C4A962] to-[#eecb74] hover:scale-105 drop-shadow-md shadow-cla-violate px-4 py-1 rounded-lg"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="article4" class="p-4 md:w-96">
            <div class="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden border-2 border-[#1C5367]">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/20692769/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  src={article4}
                  onMouseOver={()=>{pictureExpand("article4","link4","info4")}} 
                  onMouseLeave={()=>{pictureReset("article4","link4","info4")}}
                  alt="blog"
                />
              </a>
              <div id="info4" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                  Trials
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3">
                  A randomized, controlled trial of immersive virtual reality
                  analgesia, during physical therapy for pediatric burns
                </h1>
                <p class="leading-relaxed mb-3">
                  This randomized, controlled, within-subjects (crossover
                  design) study examined the effects of immersive virtual
                  reality...
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: Yuko S Schmitt, Hunter G Hoffman, David K Blough,
                  and more
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    id="link4"
                    href="https://pubmed.ncbi.nlm.nih.gov/20692769/"
                    rel="noreferrer"
                    target="_blank"
                    class="bg-gradient-to-r from-[#1C5367] to-[#2e819f] hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-white"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="article5" class="p-4 md:w-96">
            <div class="h-full rounded-xl shadow-cla-violate border-[3px] bg-gradient-to-r from-[#C4A962]/30 to-yellow-50 border-[#C4A962] overflow-hidden">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/34233179/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  src={article5}
                  onMouseOver={()=>{pictureExpand("article5","link5","info5")}} 
                  onMouseLeave={()=>{pictureReset("article5","link5","info5")}}
                  alt="blog"
                />
              </a>
              <div id="info5" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                  Health and Wellness
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3">
                  Distribution of dietary protein intake in daily meals
                  influences skeletal muscle hypertrophy
                </h1>
                <p class="leading-relaxed mb-3">
                  The meal distribution of proteins throughout the day is
                  usually skewed. However, its physiological implications and
                  the effects of better protein distribution on muscle volume
                  are largely unknown.
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: Shinya Aoyama, Hyeon-Ki Kim, Rina Hirooka, and more
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    id="link5"
                    href="https://pubmed.ncbi.nlm.nih.gov/34233179/"
                    rel="noreferrer"
                    target="_blank"
                    class="bg-gradient-to-r from-[#1C5367] to-[#2e819f] hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-white"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="article6" class="p-4 md:w-96">
            <div class="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden border-2 border-[#1C5367]">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/31911122/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-top scale-100 transition-all duration-400 hover:h-full hover:object-fill hover:max-h-[55rem]"
                  src={article6}
                  onMouseOver={()=>{pictureExpand("article6","link6","info6")}} 
                  onMouseLeave={()=>{pictureReset("article6","link6","info6")}}
                  alt="blog"
                />
              </a>
              <div id="info6" class="p-6">
                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                  Neuroendocrine System
                </h2>
                <h1 class="title-font text-lg font-medium text-gray-600 mb-3">
                  Renal Neuroendocrine Neoplasms: A Single-center Experience
                </h1>
                <p class="leading-relaxed mb-3">
                  Primary neuroendocrine neoplasms (NENs) of the kidney are
                  exceedingly rare malignancies and the available literature is
                  very limited. The natural history and response to treatments
                  is not well characterized.
                </p>
                <p class="leading-relaxed mb-3 text-gray-400">
                  Citations: Patrick W McGarrah, Gustavo F M Westin, Timothy J
                  Hobday, and more
                </p>
                <div class="flex items-center flex-wrap ">
                  <a
                    id="link6"
                    href="https://pubmed.ncbi.nlm.nih.gov/31911122/"
                    rel="noreferrer"
                    target="_blank"
                    class="bg-gradient-to-r from-[#1C5367] to-[#2e819f] hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-white"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostsBar;
