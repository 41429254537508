import React from 'react'
import LogoSolo from '../../images/LogoSolo.png'

const SmallFooter = () => {
  return (
    <footer class="p-4 bg-[#1C5367] rounded-t-lg shadow md:px-6 md:py-8 dark:bg-[#1C5367] border-b-2 border-[#C4A962]">
    <div class="sm:flex sm:items-center sm:justify-between">
    <h6 class="
            uppercase
            text-[#C4A962]
            flex
            items-center
            justify-center
            md:justify-start
          ">
          <img class="w-6 mr-3 " alt='logo' src={LogoSolo}/>
          Harmony United Research LLC
        </h6>
        <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
          <li>
            <a href='https://twitter.com/harmony_united' rel="noreferrer" target="_blank">
              <svg class="w-6 h-6 mr-6 text-[#C4A962] fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path
                  d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                />
              </svg>
            </a>
          </li>
          <li>
          <a href='https://www.instagram.com/harmonyunitedresearch/' rel="noreferrer" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mr-6 text-[#C4A962] fill-current" viewBox="0 0 16 16">
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>
          </li>
            <li>
                <a href="about_us" class="mr-4 hover:underline md:mr-6 text-[#C4A962] ">About</a>
            </li>
            {/* <li>
                <a href="legal" class="mr-4 hover:underline md:mr-6 text-[#C4A962]">Privacy Policy</a>
            </li> */}
            <li>
                <a href="careers" class="mr-4 hover:underline md:mr-6 text-[#C4A962]">Careers</a>
            </li>
            <li>
                <a href="sign_up_for_trial" class="hover:underline text-[#C4A962]">Sign Up</a>
            </li>
        </ul>
    </div>
    <hr class="my-6 border-[#C4A962] border-[1px] sm:mx-auto lg:my-8" />
    <span class="block text-sm text-[#C4A962] sm:text-center">© 2022 <a href="https://" class="hover:underline">Harmony United Research LLC®</a>. All Rights Reserved.
    </span>
</footer>
  )
}

export default SmallFooter